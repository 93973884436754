export default function(n, x, s, c) {
    try {
        n = parseFloat(n)
        isNaN(n) && (n = 0);
    } catch (error) {
        n = 0;
    }
    var re = '\\d(?=(\\d{' + (x || 3) + '})+\\D)',
        num = n.toFixed(2);

    return num.replace('.', c || ',').replace(new RegExp(re, 'g'), '$&' + (s || '.'));
};