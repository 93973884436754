import * as Actions from '../actions'

const initialState = {
  currentUser: {},
  userRole: "",
  loading: false,
  loggedInWith: "",
  loginError: null,
  activateResult: null,
  forgotPasswordResult: null
}

export const login = (state = initialState, action) => {

  switch (action.type) {
    case  Actions.LOGIN_WITH_JWT: {
      let data = action.payload;
      if (data === undefined)
        data = {}
      data.role = "";

      return {
        ...state,
        currentUser: data ? data : {},
        userRole: data.role ? data.role : "",
        loading: data.loading,
        loggedInWith: data.loggedInWith,
        loginError: data.loginError
      }
    }
    case Actions.SET_USER_DATA: {
      return {
        ...state, currentUser: action.payload ?? {},
        userRole: action.payload.role ?? ""
      }
    }
    case Actions.USER_ACTIVATE: {
      return {...state, activateResult: action.payload}
    }
    case Actions.SET_LOADING: {
      return {...state, loading: action.payload}
    }
    case Actions.FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPasswordResult: action.payload
      }
    }
    case Actions.CHANGE_ROLE: {
      return {...state, userRole: action.userRole}
    }
    case Actions.LOCK_USER: {
      if (state.currentUser && state.currentUser.email) {
        return {...state, currentUser: {...state.currentUser, locked: true}}
      } else {
        return state;
      }
    }
    case Actions.UNLOCK_USER: {
      if (state.currentUser && state.currentUser.email) {
        return {...state, currentUser: {...state.currentUser, locked: false}}
      } else {
        return state;
      }
    }
    default: {
      return state
    }
  }
}
