import {combineReducers} from "redux"
import customizer from "./customizer/"
import auth from "../../views/pages/authentication/login/store/reducers"
import navbar from "./navbar/Index"

const rootReducer = asyncReducers => combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  ...asyncReducers
})

export default rootReducer
