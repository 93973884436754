import axios from "axios"
import jwtService from '../../../../../../services/jwtService/jwtService';

export const LOGIN_WITH_JWT = "[LOGIN] LOGIN_WITH_JWT";
export const SET_USER_DATA = "[LOGIN] SET_USER_DATA";
export const SET_LOADING = "[LOGIN] SET_LOADING";
export const USER_ACTIVATE = "[LOGIN] USER ACTIVATE";
export const FORGOT_PASSWORD = "[LOGIN] FORGOT PASSWORD";
export const LOCK_USER = "[LOGIN] LOGIN_LOCK_USER";
export const UNLOCK_USER = "[LOGIN] LOGIN_UNLOCK_USER";
export const CHANGE_ROLE = "[LOGIN] CHANGE_ROLE";

export function loginWithJWT(user) {
  return dispatch => {
    dispatch({
      type: SET_LOADING,
      payload: true
    });
    jwtService
      .signInWithEmailAndPassword(user)
      .then((usr) => {
        dispatch({
          type: LOGIN_WITH_JWT,
          payload: {usr, loggedInWith: "jwt", loading: false}
        });
        dispatch(setUserData(usr));
      }, (err) => {
        dispatch({
          type: LOGIN_WITH_JWT,
          payload: {loggedInWith: "error", loading: false, loginError: err}
        });
      })
  }
}

export const logout = (newUserData) => {
  return (dispatch) => {
    jwtService.logout();
    dispatch({
      type: SET_USER_DATA,
      payload: newUserData || {}
    });
    jwtService.emit('goLoginPage');
  }
}

export const isLoggedIn = () => {
  return dispatch => {
    axios
      .get("/isLoggedIn")
      .then(response => {
        let usr = JSON.parse(localStorage.getItem("user"));
        dispatch({
          type: LOGIN_WITH_JWT,
          payload: {usr, loggedInWith: "jwt"}
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
}

export const activateUserWithToken = (reqPayload) => {
  return (dispatch) => {
    axios.post("/users/activate", reqPayload)
      .then(response => {
        dispatch({
          type: USER_ACTIVATE,
          payload: response.data.message
        })
      })
      .catch(err => {
        dispatch({
          type: USER_ACTIVATE,
          payload: err
        })
      })
  }
}

export const setUserData = (data) => {
  return (dispatch) => {
    if (data) {
      if (localStorage.getItem("user"))
        localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(data));
      dispatch({
        type: SET_USER_DATA,
        payload: data
      });
      return Promise.resolve();
    }
  };
}

export const forgotPassword = (emailAddress) => {
  return dispatch => {
    axios.post('/forgotpassword', {
      email: emailAddress
    }).then((res) => {
      dispatch({
        type: FORGOT_PASSWORD,
        payload: res.data.message
      })
    }).catch(err => {
      dispatch({
        type: FORGOT_PASSWORD,
        payload: err
      })
    })
  }
}
