import moment from "moment";
import NumberFormatter from 'utility/NumberFormatter'

class EventEmitter {

  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set();
    }
    return this.events[eventName]
  }

  on(eventName, fn) {
    this._getEventListByName(eventName).add(fn);
  }

  once(eventName, fn) {

    const self = this;

    const onceFn = function (...args) {
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);

  }

  emit(eventName, ...args) {

    this._getEventListByName(eventName).forEach(function (fn) {

      fn.apply(this, args);

    }.bind(this));

  }

  removeListener(eventName, fn) {
    this._getEventListByName(eventName).delete(fn);
  }
}

class Utils {

  static filterArrayByString(mainArr, searchText) {
    if (searchText === '') {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter(itemObj => {
      return this.searchInObj(itemObj, searchText);
    });
  };

  static searchInObj(itemObj, searchText) {
    for (const prop in itemObj) {
      if (!itemObj.hasOwnProperty(prop)) {
        continue;
      }

      const value = itemObj[prop];

      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (typeof value === "number") {
        if (this.searchInString(String(value), searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  static searchInArray(arr, searchText) {
    for (const value of arr) {
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  static searchInString(value, searchText) {
    return value.toLowerCase().includes(searchText);
  }

  static generateGUID() {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return S4() + S4();
  }

  static toggleInArray(item, array) {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  /**
   *
   * @param {String|String[]} permissionName
   */
  static checkPermission(permissionName) {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    let authArr = [];
    if (Array.isArray(permissionName))
      authArr = permissionName;
    else
      authArr.push(permissionName);
    /**
     * If auth array is not defined
     * Pass and allow
     */
    if (authArr === null || authArr === undefined) {
      return true;
    }
    /**
     * if auth array is empty means,
     * allow only user role is guest (null or empty[])
     */
    else if (authArr.length === 0) {
      return !permissions || permissions.length === 0;
    }
    /**
     * Check if user has grants
     */
    else {
      /*
      Check if user role is array,
      */
      if (permissions && Array.isArray(permissions) && authArr.length === 1) {
        return authArr.some(r => permissions.indexOf(r) >= 0);
      }
      if (permissions && Array.isArray(permissions) && authArr.length > 1) {
        let arr = [];
        authArr.some(r => {
          let a = permissions.indexOf(r) >= 0;
          if (a)
            arr.push(a);
        });
        return arr.length === authArr.length;
      }

      /*
      Check if user role is string,
      */
      return authArr.includes(permissions);
    }
  }

  static handleize(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/\W+/g, '')       // Remove all non-word chars
      .replace(/--+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }

  static setRoutes(config, defaultAuth) {
    let routes = [...config.routes];

    if (config.settings || config.auth) {
      routes = routes.map((route) => {
        let auth = config.auth ? [...config.auth] : defaultAuth || null;
        auth = route.auth ? [...auth, ...route.auth] : auth;
        return {
          ...route,
          settings: {...config.settings, ...route.settings},
          auth
        };
      });
    }

    return [...routes];
  }

  static generateRoutesFromConfigs(configs, defaultAuth) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [
        ...allRoutes,
        ...this.setRoutes(config, defaultAuth)
      ]
    });
    return allRoutes;
  }

  static findById(o, id) {
    //Early return
    if (o.id === id) {
      return o;
    }
    let result, p;
    for (p in o) {
      if (o.hasOwnProperty(p) && typeof o[p] === 'object') {
        result = this.findById(o[p], id);
        if (result) {
          return result;
        }
      }
    }
    return result;
  }

  static EventEmitter = EventEmitter;

  static hasPermission(authArr, userRole) {
    /**
     * If auth array is not defined
     * Pass and allow
     */
    if (authArr === null || authArr === undefined) {
      // console.info("auth is null || undefined:", authArr);
      return true;
    }
    /**
     * if auth array is empty means,
     * allow only user role is guest (null or empty[])
     */
    else if (authArr.length === 0) {
      // console.info("auth is empty[]:", authArr);
      return !userRole || userRole.length === 0;
    }
    /**
     * Check if user has grants
     */
    else {
      // console.info("auth arr:", authArr);
      /*
      Check if user role is array,
      */
      if (userRole && Array.isArray(userRole) && authArr.length === 1) {
        return authArr.some(r => userRole.indexOf(r) >= 0);
      }
      if (userRole && Array.isArray(userRole) && authArr.length > 1) {
        let arr = [];
        authArr.some(r => {
          let a = userRole.indexOf(r) >= 0;
          if (a)
            arr.push(a);
        });
        return arr.length === authArr.length;
      }

      /*
      Check if user role is string,
      */
      return authArr.includes(userRole);
    }
  }

  static printThermal = (listData, currentUser) => {

    let boothName;

    if (!!window.visionf && window.visionf.isElectron && localStorage.getItem('user')) {
      try {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.booth && user.booth.printCount > 1) {
          boothName = user.booth.boothName;
        }
      } catch (error) {
        console.error(error);
      }
    }

    listData.tickets.forEach(ticket => {
      let commands = [
        {
          type: 'qrCode',
          value: ticket._id,
          height: 110,
          width: 110,
          position: 'center',
          style: 'margin-right: auto; margin-left: auto; margin-top: 10px;'
        },
        {
          type: 'table',
          style: 'border: 0px; font-weight: 700;',
          tableHeader: ['Ürün', 'Adet', 'Fiyat', 'Toplam'],
          tableBody: [
            [{type: 'text', value: listData.salesName, style: "text-align: left; width: 100px;"}, {
              type: 'text',
              value: 1
            }, {
              type: 'text',
              value: NumberFormatter(listData.price) + " ₺"
            }, {
              type: 'text',
              value: NumberFormatter(listData.price) + " ₺"
            }]
          ],
          tableBodyStyle: 'color: black; font-weight": 500;',
        }, {
          type: 'table',
          style: 'border: 0px; font-weight: 700;',
          tableHeader: ['', ''],
          tableBody: [
            // ["Toplam Tutar", (NumberFormatter(giftPrice + totalPrice) + " ₺")],
            // ["Hediye Ürün İndirimi", (NumberFormatter(giftPrice) + " ₺")],
            // ["Kampanya İndirimi", (NumberFormatter(priceOff) + " ₺")],
            // ["Toplam İndirim Oranı", (NumberFormatter(discountPercentage + giftPercentage) + " %")],
            // ["KDV Tutarı (Ücretlere Dahildir*)", (NumberFormatter(vatPrice) + " ₺")],
            // [" ", " "],
            ["KDV Dahil Toplam Ücret", (NumberFormatter(listData.price) + " ₺")]
          ],
          tableBodyStyle: 'color: black; font-weight": 500;'
        },
        {
          type: 'text',
          value: "Mali Değeri Yoktur*",
          style: `text-align:center;`,
          css: {"font-weight": "700", "font-size": "10px"}
        },
        {
          type: 'text',                                       // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
          value: 'VIA ENTERTAINMENT',
          style: `text-align:center;`,
          css: {
            "font-weight": "700",
            "font-size": "18px",
            "position": "absolute",
            "bottom": "80px",
            "text-align": "center"
          }
        },
        {
          type: 'text',
          value: `PNR: ${ticket.pnrCode || '-'}`,
          style: `text-align:center;`,
          css: {
            "font-weight": "700",
            "font-size": "14px",
            "margin-top": "10px",
            "position": "absolute",
            "bottom": "65px",
            "text-align": "center"
          }
        }, {
          type: 'text',
          value: (!!currentUser ? `${currentUser.name} ${(currentUser.surname || '').slice(0, 1)}` : '') + ' ' + (moment(ticket.processTime || ticket.createdAt).format("DD.MM.YYYY HH:mm:ss")),
          style: `text-align:center;`,
          css: {
            "font-weight": "700",
            "font-size": "14px",
            "position": "absolute",
            "bottom": "50px",
            "text-align": "center"
          }
        }, {
          type: 'text',
          value: `Gişe: ${boothName || '-'}`,
          style: `text-align:center;`,
          css: {
            "font-weight": "700",
            "font-size": "14px",
            "position": "absolute",
            "bottom": "35px",
            "text-align": "center"
          }
        }
      ];
      if (window.visionf && window.visionf.printTicket) {
        window.visionf.printTicket(null, {
          commands: commands
        }).then(res => {
          console.log("Bilet Başarıyla Yazdırıldı");
          //this.props.toggleModal();
        }).catch(err => {
          console.log(err);
          console.log("Yazdırma İşlemi Başarısız Oldu");
        });
      } else {
        console.log("Yazdırma Servisi ile iletişime geçilemedi")
      }

    })

  }

  static currentUserId = () => {
    let tmpUser = localStorage.getItem('user')
    return tmpUser ? JSON.parse(tmpUser)._id : false
  }

}

export default Utils;
