import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import {connect} from "react-redux";
import {logout} from "../../../views/pages/authentication/login/store/actions";
import {withTranslation} from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import {history} from "../../../history"

const mapStateToProps = state => {
  return {
    values: state.auth.login
  }
}

const UserDropdown = props => {
  const {t} = props;

  return (
    <DropdownMenu right>
      <DropdownItem tag="a" onClick={() => {
        history.push("/user/edit/" + JSON.parse(localStorage.getItem("user"))._id);
      }}>
        <Icon.Settings size={14} className="mr-50"/>
        <span className="align-middle">{t("navbar.editProfile")}</span>
      </DropdownItem>
      <DropdownItem divider/>
      <DropdownItem
        tag="a"
        onClick={e => props.logout()}
      >
        <Icon.Power size={14} className="mr-50"/>
        <span className="align-middle">{t("navbar.logout")}</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: []
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({data}) => {
      this.setState({suggestions: data.searchResult})
    })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  render() {
    const {t, i18n} = this.props;

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledButtonDropdown>
          <DropdownToggle color="flat-light" caret>
            <ReactCountryFlag
              className="country-flag"
              style={{
                width: '1.3em',
                height: '1.3em',
              }}
              countryCode={
                i18n.language === "en"
                  ? "us"
                  : i18n.language
              }
              svg
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="a" onClick={() => i18n.changeLanguage("tr")}>
              <ReactCountryFlag
                className="country-flag"
                countryCode="tr"
                svg
              /> Türkçe</DropdownItem>
            <DropdownItem tag="a" onClick={() => i18n.changeLanguage("en")}>
              <ReactCountryFlag
                className="country-flag"
                countryCode="us"
                svg
              /> English</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search"/>
          </NavLink>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary"/>
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder={t('general.search')}
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={e => {
                this.setState({navbarSearch: false})
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"]
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17}/>
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                  ? item.email
                                  : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                                ? item.date
                                : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                )
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput)
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation()
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }}
              />
            </div>
          </div>
        </NavItem>
        {/*<UncontrolledDropdown*/}
        {/*  tag="li"*/}
        {/*  className="dropdown-notification nav-item"*/}
        {/*>*/}
        {/*  <DropdownToggle tag="a" className="nav-link nav-link-label">*/}
        {/*    <Icon.Bell size={21}/>*/}
        {/*    <Badge pill color="primary" className="badge-up">*/}
        {/*      {" "}*/}
        {/*      5{" "}*/}
        {/*    </Badge>*/}
        {/*  </DropdownToggle>*/}
        {/*  <DropdownMenu tag="ul" right className="dropdown-menu-media">*/}
        {/*    <li className="dropdown-menu-header">*/}
        {/*      <div className="dropdown-header mt-0">*/}
        {/*        <h3 className="text-white">5 New</h3>*/}
        {/*        <span className="notification-title">App Notifications</span>*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*    <PerfectScrollbar*/}
        {/*      className="media-list overflow-hidden position-relative"*/}
        {/*      options={{*/}
        {/*        wheelPropagation: false*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <div className="d-flex justify-content-between">*/}
        {/*        <Media className="d-flex align-items-start">*/}
        {/*          <Media left href="#">*/}
        {/*            <Icon.PlusSquare*/}
        {/*              className="font-medium-5 primary"*/}
        {/*              size={21}*/}
        {/*            />*/}
        {/*          </Media>*/}
        {/*          <Media body>*/}
        {/*            <Media heading className="primary media-heading" tag="h6">*/}
        {/*              You have new order!*/}
        {/*            </Media>*/}
        {/*            <p className="notification-text">*/}
        {/*              Are your going to meet me tonight?*/}
        {/*            </p>*/}
        {/*          </Media>*/}
        {/*          <small>*/}
        {/*            <time*/}
        {/*              className="media-meta"*/}
        {/*              dateTime="2015-06-11T18:29:20+08:00"*/}
        {/*            >*/}
        {/*              9 hours ago*/}
        {/*            </time>*/}
        {/*          </small>*/}
        {/*        </Media>*/}
        {/*      </div>*/}
        {/*      <div className="d-flex justify-content-between">*/}
        {/*        <Media className="d-flex align-items-start">*/}
        {/*          <Media left href="#">*/}
        {/*            <Icon.DownloadCloud*/}
        {/*              className="font-medium-5 success"*/}
        {/*              size={21}*/}
        {/*            />*/}
        {/*          </Media>*/}
        {/*          <Media body>*/}
        {/*            <Media heading className="success media-heading" tag="h6">*/}
        {/*              99% Server load*/}
        {/*            </Media>*/}
        {/*            <p className="notification-text">*/}
        {/*              You got new order of goods?*/}
        {/*            </p>*/}
        {/*          </Media>*/}
        {/*          <small>*/}
        {/*            <time*/}
        {/*              className="media-meta"*/}
        {/*              dateTime="2015-06-11T18:29:20+08:00"*/}
        {/*            >*/}
        {/*              5 hours ago*/}
        {/*            </time>*/}
        {/*          </small>*/}
        {/*        </Media>*/}
        {/*      </div>*/}
        {/*      <div className="d-flex justify-content-between">*/}
        {/*        <Media className="d-flex align-items-start">*/}
        {/*          <Media left href="#">*/}
        {/*            <Icon.AlertTriangle*/}
        {/*              className="font-medium-5 danger"*/}
        {/*              size={21}*/}
        {/*            />*/}
        {/*          </Media>*/}
        {/*          <Media body>*/}
        {/*            <Media heading className="danger media-heading" tag="h6">*/}
        {/*              Warning Notification*/}
        {/*            </Media>*/}
        {/*            <p className="notification-text">*/}
        {/*              Server has used 99% of CPU*/}
        {/*            </p>*/}
        {/*          </Media>*/}
        {/*          <small>*/}
        {/*            <time*/}
        {/*              className="media-meta"*/}
        {/*              dateTime="2015-06-11T18:29:20+08:00"*/}
        {/*            >*/}
        {/*              Today*/}
        {/*            </time>*/}
        {/*          </small>*/}
        {/*        </Media>*/}
        {/*      </div>*/}
        {/*      <div className="d-flex justify-content-between">*/}
        {/*        <Media className="d-flex align-items-start">*/}
        {/*          <Media left href="#">*/}
        {/*            <Icon.CheckCircle*/}
        {/*              className="font-medium-5 info"*/}
        {/*              size={21}*/}
        {/*            />*/}
        {/*          </Media>*/}
        {/*          <Media body>*/}
        {/*            <Media heading className="info media-heading" tag="h6">*/}
        {/*              Complete the task*/}
        {/*            </Media>*/}
        {/*            <p className="notification-text">*/}
        {/*              One of your task is pending.*/}
        {/*            </p>*/}
        {/*          </Media>*/}
        {/*          <small>*/}
        {/*            <time*/}
        {/*              className="media-meta"*/}
        {/*              dateTime="2015-06-11T18:29:20+08:00"*/}
        {/*            >*/}
        {/*              Last week*/}
        {/*            </time>*/}
        {/*          </small>*/}
        {/*        </Media>*/}
        {/*      </div>*/}
        {/*      <div className="d-flex justify-content-between">*/}
        {/*        <Media className="d-flex align-items-start">*/}
        {/*          <Media left href="#">*/}
        {/*            <Icon.File className="font-medium-5 warning" size={21}/>*/}
        {/*          </Media>*/}
        {/*          <Media body>*/}
        {/*            <Media heading className="warning media-heading" tag="h6">*/}
        {/*              Generate monthly report*/}
        {/*            </Media>*/}
        {/*            <p className="notification-text">*/}
        {/*              Reminder to generate monthly report*/}
        {/*            </p>*/}
        {/*          </Media>*/}
        {/*          <small>*/}
        {/*            <time*/}
        {/*              className="media-meta"*/}
        {/*              dateTime="2015-06-11T18:29:20+08:00"*/}
        {/*            >*/}
        {/*              Last month*/}
        {/*            </time>*/}
        {/*          </small>*/}
        {/*        </Media>*/}
        {/*      </div>*/}
        {/*    </PerfectScrollbar>*/}
        {/*    <li className="dropdown-menu-footer">*/}
        {/*      <DropdownItem tag="a" className="p-1 text-center">*/}
        {/*        <span className="align-middle">Read all notifications</span>*/}
        {/*      </DropdownItem>*/}
        {/*    </li>*/}
        {/*  </DropdownMenu>*/}
        {/*</UncontrolledDropdown>*/}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.values.currentUser.name}
              </span>
              <span className="user-status">{this.props.values.currentUser.role}</span>
            </div>
            <span data-tour="user">
              {this.props.values.currentUser && this.props.values.currentUser.img ? <img
                src={"data:image/" + this.props.values.currentUser.img.fileType + ";base64," + (this.props.values.currentUser.img.base64Data ?? "")}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              /> : null
              }
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

export default connect(mapStateToProps, {logout})(withTranslation()(NavbarUser))
