import { createStore, applyMiddleware, compose } from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "./reducers/rootReducer"

const middlewares = [thunk, createDebounce(), /*require("redux-logger").logger*/]

// if (process.env.DEV_ENV === 'development') {
//   const { logger } = require('redux-logger');
//   middlewares.push(logger);
//}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if ( store.asyncReducers[key] )
  {
    return;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(rootReducer(store.asyncReducers));
  return store;
};

export { store }
