import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
  {
    id: "home",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/"
  },
  {
    id: "product",
    title: {translate: "sideMenu.productmanagement"},
    type: "item",
    icon: <Icon.Book size={20}/>,
    navLink: "/product/list",
    permissions: ["showProducts"]
  },
  {
    id: "categories",
    title: {translate: "sideMenu.categoriesmanagement"},
    type: "item",
    icon:  <Icon.Server size={20}  />,
    navLink: "/categoriesManagement",
    permissions: ["showCategories"]
  },
  {
    id: "seller",
    title: {translate: "sideMenu.sellermanagement"},
    type: "item",
    icon: <Icon.ShoppingBag size={20}/>,
    navLink: "/seller/list",
    permissions: ["showSellers"]
  },
  {
    id: "campaignmanagement",
    title: {translate: "sideMenu.campaignmanagement"},
    type: "item",
    icon:   <Icon.Gift size={20} />,
    navLink: "/campaignmanagement",
    permissions: ["showCampaigns"]
  },
  {
    id: "middleman",
    title: {translate: "sideMenu.middlemanmanagement"},
    type: "item",
    icon: <Icon.Percent size={20}/>,
    navLink: "/middleman/list",
    permissions: ["showMiddlemans"]
  },
  {
    id: "booth",
    title: {translate: "sideMenu.boothmanagement"},
    type: "item",
    icon: <Icon.MapPin size={20}/>,
    navLink: "/booth/list",
    permissions: ["showBooths"],
  },
  {
    id: "visitormanagement",
    title: {translate: "sideMenu.visitormanagement"},
    type: "item",
    icon: <Icon.Users size={20}/>,
    navLink: "/visitor/list",
    permissions: ["showVisitorManagement"]
  },
  {
    id: "agency",
    title: {translate: "sideMenu.presalemanagement"},
    type: "item",
    icon: <Icon.Layers size={20}/>,
    navLink: "/presale/home",
    permissions: ["showPreSales"]
  },
  {
    id: "onlineagency",
    title: {translate: "sideMenu.presaleservicemanagement"},
    type: "item",
    icon: <Icon.Layers size={20}/>,
    navLink: "/onlinesale/home",
    permissions: ["showPreSales"]
  },
  {
    id: "turnstileManagement",
    title: {translate: "sideMenu.turnstilemanagement"},
    type: "item",
    icon: <Icon.Cpu size={20}/>,
    navLink: "/turnstile/list",
    permissions: ["showTurnstiles"]
  },
  {
    id: "ticket",
    title: {translate: "sideMenu.ticketmanagement"},
    type: "item",
    icon: <Icon.CreditCard size={20}/>,
    navLink: "/ticket/list",
    permissions: ["showTickets"]
  },
  {
    id: "bundle",
    title: {translate: "sideMenu.bundlemanagement"},
    type: "item",
    icon:  <Icon.ShoppingCart size={20}  />,
    navLink: "/bundleManagement",
    permissions: ["showBundles"]
  },
  {
    id: "campaigncode",
    title: {translate: "sideMenu.discountmanagement"},
    type: "item",
    icon:  <Icon.Tag size={20}  />,
    navLink: "/discountmanagement",
    permissions: ["readDiscount"]
  },
  {
    id: "cinema",
    title: {translate: "sideMenu.cinemamanagement"},
    type: "collapse",
    icon: <Icon.Video size={16}/>,
    children: [
      {
        id: "film",
        title: {translate: "sideMenu.filmmanagement"},
        type: "item",
        icon: <Icon.Film size={20}/>,
        navLink: "/cinema/list",
        permissions: ["showCinema"]
      },
      {
        id: "saloon",
        title: {translate: "sideMenu.saloonmanagement"},
        type: "item",
        icon: <Icon.Grid size={20} />,
        navLink: "/saloon/list",
        permissions: ["showSaloon"]
      },
      {
        id: "session",
        title: {translate: "sideMenu.sesssionmanagement"},
        type: "item",
        icon:   <Icon.Settings size={20} />,
        navLink: "/session/list",
        permissions: ["showSession"]
      }
    ]
  },
  {
    id: "saleshome",
    title: "Satış",
    type: "item",
    icon:  <Icon.Terminal size={20}  />,
    navLink: "/sales/home",
    permissions: ["showSalesPage"]
  },
  {
    id: "reportManagement",
    title: {translate: "sideMenu.reportmanagement"},
    type: "item",
    icon: <Icon.List size={20}/>,
    navLink: "/reportAccounting",
    permissions: ["salesSearchAccountingReport"]
  },
  {
    id: "management",
    title: {translate: "sideMenu.management"},
    type: "collapse",
    icon: <Icon.Layout size={16}/>,
    children: [
      {
        id: "user",
        title: {translate: "sideMenu.usermanagement"},
        type: "item",
        icon: <Icon.User size={20}/>,
        navLink: "/user/list",
        permissions: ["showUsers"]
      },
      {
        id: "roleManagement",
        title: {translate: "sideMenu.rolemanagement"},
        type: "item",
        icon: <Icon.Grid size={20} />,
        navLink: "/roleManagement",
        permissions: ["showRoles"]
      },
      {
        id: "customization",
        title: {translate: "sideMenu.customizationmanagement"},
        type: "item",
        icon:   <Icon.Settings size={20} />,
        navLink: "/customizationManagement",
        permissions: ["showCustomization"]
      },
      {
        id: "workflow",
        title: {translate: "sideMenu.workflowmanagement"},
        type: "item",
        icon: <Icon.ChevronsRight size={20}/>,
        navLink: "/workflow/list",
        permissions: ["getWorkflows"]
      },
    ]
  }
]

export default navigationConfig
